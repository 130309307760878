import React, { useState } from 'react';

function AccountDeletion() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the email to your server for processing
    console.log('Email submitted for account deletion:', email);
    setSubmitted(true);
  };

  return (
    <div style={styles.container}>
      {!submitted ? (
        <form onSubmit={handleSubmit} style={styles.form}>
          <label style={styles.label}>
            Enter your email to delete account:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={styles.input}
            />
          </label>
          <button type="submit" style={styles.button}>Submit</button>
        </form>
      ) : (
        <p style={styles.message}>Your account will be deleted within 15 days of the request being raised.</p>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Arial, sans-serif',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#f2f2f2',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  label: {
    marginBottom: '10px',
    fontSize: '1.2em',
    color: '#333',
  },
  input: {
    padding: '10px',
    marginBottom: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    width: '100%',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1em',
  },
  buttonHover: {
    backgroundColor: '#45a049',
  },
  message: {
    fontSize: '1.2em',
    color: '#333',
    backgroundColor: '#e7f3fe',
    padding: '15px',
    border: '1px solid #b3d4fc',
    borderRadius: '5px',
  },
};

export default AccountDeletion;
