import { Route, Routes } from 'react-router-dom';
import LandingPage from './components/Main/LandingPage'
import ThankYou from './components/Main/ThankYou'
import AccountDelete from './components/Main/AccountDelete'

export function TempApp() {
    return (
        <>

            <Routes>
               <Route path="/" element={<LandingPage />} />
               <Route path="/email-confirm" element={<ThankYou />} />
               <Route path="/account-delete" element={<AccountDelete />} />

            </Routes>
        </>
    );
}


