import React, { useState } from 'react';
import { supabase } from '../../supabaseClient';
import { Link,useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// import ReCAPTCHA from "react-google-recaptcha";
import {useAuth }  from '../../context/AuthContext'; // Adjust the path as per your project structure


export default function Login() {
    const { setUser } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    // const [recaptchaValue, setRecaptchaValue] = useState(null);

    const loginHandle = async () => {

        const { data, error } = await supabase.auth.signInWithPassword({ email, password });

        if (error) {
            Swal.fire("Login failed", '', 'error');
        } else if (data) {
            setUser(data.user);
            Swal.fire("Login success", '', 'success');
            navigate('/')
        }
    };

    // function onChange(value) {
    //     console.log("Captcha value:", value);
    //     setRecaptchaValue(value);
    // }

    return (
        <div className="container">
            <div className="row justify-content-center align-items-center jumbotron" style={{ height: '65vh' }}>
                <div className="col-md-5">
                    <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                        <div className="card-header border-bottom text-center">
                            <h2>Login</h2>
                        </div>
                        <div className="card-body">
                            <input 
                                type="email" 
                                placeholder="Enter Your Email" 
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <input 
                                type="password" 
                                placeholder="Enter Your Password" 
                                className="form-control my-3"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {/* <ReCAPTCHA
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                onChange={onChange}
                            /> */}
                            <button type="submit" onClick={loginHandle} className="btn btn-primary mt-2">Login</button>
                            <Link to="/forgot-password"><p>Forgot Password</p></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
