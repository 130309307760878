import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { TempApp } from './TempApp';
import { AuthProvider } from './context/AuthContext';


const subdomain = window.location.hostname.split('.')[0];
console.log("subdomain====", subdomain);

const rootElementId = subdomain === 'admin' ? 'root' : 'main';
const RootComponent = subdomain === 'admin' ? App : TempApp;

const root = ReactDOM.createRoot(document.getElementById(rootElementId));
root.render(
  <BrowserRouter>
<AuthProvider>
    <RootComponent />
  </AuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
