import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import LogoFav from '../../assets/Main/logo.ico';
const ThankYou = () => {
  return (
    <Section>
      <Helmet>
        <title>FlexiJoin - Manage Your Members</title>
        <link rel="icon" href={LogoFav} />
        <meta name="description" content="FlexiJoin—a comprehensive platform designed for business owners in the fitness and wellness industry. Whether you run a gym, dance class, or yoga studio, FlexiJoin provides the tools you need to effectively manage your members and grow your business"/>
      </Helmet>
      <Content>
        <h1>Thank You!</h1>
        <p>Your account has been successfully confirmed.</p>
        <p>We're excited to have you on board. You can now start using FlexiJoin to manage your members efficiently.</p>
        {/* <Button href="/">Go to Dashboard</Button> */}
      </Content>
       </Section>
  );
};

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
  text-align: center;
`;

const Content = styled.div`
  max-width: 600px;
  width: 100%;
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin: 20px;

  h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      font-size: 2em;
    }
  }

  p {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      font-size: 1em;
    }
  }
`;

const Button = styled.a`
  display: inline-block;
  padding: 15px 30px;
  font-size: 1.1em;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    padding: 12px 24px;
    font-size: 1em;
  }
`;

const Footer = styled.footer`
  margin-top: 40px;
  font-size: 1em;
  color: #fff;

  a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: #f0e68c;
    }
  }

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  p {
    margin-right: 10px;
    color: #fff;
  }

  a {
    color: #fff;
    margin: 0 10px;
    font-size: 1.5em;

    &:hover {
      color: #f0e68c;
    }
  }
`;

export default ThankYou;
