import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import { Carousel } from 'react-responsive-carousel';
import LogoFlex from '../../assets/Main/logo.png';
import LogoFav from '../../assets/Main/logo.ico';
import s1 from '../../assets/Main/s1.jpg';
import s2 from '../../assets/Main/s2.jpg';
import s3 from '../../assets/Main/s3.jpg';
import s4 from '../../assets/Main/s4.jpg';
import playstore from '../../assets/Main/playstore.png';
import appstore from '../../assets/Main/appstore.png';

const AboutApp = () => {
  return (
    <Container>
      <Helmet>
        <title>FlexiJoin - Manage Your Members</title>
        <link rel="icon" href={LogoFav} />
        <meta name="description" content="FlexiJoin—a comprehensive platform designed for business owners in the fitness and wellness industry. Whether you run a gym, dance class, or yoga studio, FlexiJoin provides the tools you need to effectively manage your members and grow your business"/>
      </Helmet>
      <LeftPanel>
        <Content>
          <LogoRow>
            <LogoContainer>
              <Logo src={LogoFlex} alt="FlexiJoin Logo" />
            </LogoContainer>
            <StoreLinks>
              <StoreLink href="https://play.google.com/store/apps/details?id=com.coolprogramer.FlexiJoin&hl=en" target="_blank" rel="noopener noreferrer">
                <StoreLogo src={playstore} alt="Play Store" />
                Available on Play Store
              </StoreLink>
              <StoreLink href="#" target="_blank" rel="noopener noreferrer">
                <StoreLogo src={appstore} alt="App Store" />
                Coming Soon to Apple Store
              </StoreLink>
            </StoreLinks>
          </LogoRow>
          <SubHeading>Streamline Your Business Management and Growth</SubHeading>
          <Text>
            Welcome to FlexiJoin—a comprehensive platform designed for business owners in the fitness and wellness industry. Whether you run a gym, dance class, or yoga studio, FlexiJoin provides the tools you need to effectively manage your members and grow your business.
          </Text>
          <KeyFeatures>
            <FeatureTitle>Key Features:</FeatureTitle>
            <FeatureList>
              <FeatureItem>Effortless Member Management: Simplify the onboarding, tracking, and renewing processes for your members.</FeatureItem>
              <FeatureItem>Engagement Tools: Keep your clients informed and engaged with real-time notifications, updates, and communication channels.</FeatureItem>
              <FeatureItem>Analytics & Insights: Gain valuable insights into your business performance with powerful analytics tools.</FeatureItem>
            </FeatureList>
            <Text>
              With FlexiJoin, you can focus more on delivering quality services while we handle the management side. Join us and watch your business thrive!
            </Text>
          </KeyFeatures>
        </Content>
      </LeftPanel>
      <RightPanel>
        <ScreenshotContainer>
          <Carousel showThumbs={false} infiniteLoop useKeyboardArrows autoPlay>
            <div>
              <Screenshot src={s1} alt="App Screenshot 1" />
            </div>
            <div>
              <Screenshot src={s2} alt="App Screenshot 2" />
            </div>
            <div>
              <Screenshot src={s3} alt="App Screenshot 3" />
            </div>
            <div>
              <Screenshot src={s4} alt="App Screenshot 4" />
            </div>
          </Carousel>
        </ScreenshotContainer>
      </RightPanel>
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 20px;
  height: 100vh;
  background: linear-gradient(to bottom, #007bff, #80d0ff);
  font-family: 'Arial', sans-serif;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px 10px;
  }
`;

const LeftPanel = styled.div`
  flex: 1;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

const LogoRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LogoContainer = styled.div`
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const Logo = styled.img`
  max-width: 200px;
  height: auto;

  @media (max-width: 480px) {
    max-width: 150px;
  }
`;

const Content = styled.div`
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const SubHeading = styled.h2`
  font-size: 1.5em;
  color: #000;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    font-size: 1.2em;
  }
`;

const Text = styled.p`
  font-size: 1.2em;
  color: #000;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    font-size: 1em;
  }
`;

const KeyFeatures = styled.div`
  margin-bottom: 40px;

  @media (max-width: 480px) {
    margin-bottom: 30px;
  }
`;

const FeatureTitle = styled.h3`
  font-size: 1.8em;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    font-size: 1.5em;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;

  @media (max-width: 480px) {
    margin: 15px 0;
  }
`;

const FeatureItem = styled.li`
  font-size: 1.2em;
  color: #000;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    font-size: 1em;
  }
`;

const StoreLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StoreLink = styled.a`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  font-size: 1.1em;
  color: #333;
  background-color: #FFD700;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #FFC107;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 480px) {
    font-size: 1em;
  }
`;

const StoreLogo = styled.img`
  max-width: 30px;
  height: auto;
  margin-right: 10px;

  @media (max-width: 480px) {
    max-width: 20px;
    margin-right: 5px;
  }
`;

const RightPanel = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const ScreenshotContainer = styled.div`
  border: 2px solid black;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.9);
  width: 360px;
  height: 640px;
  overflow-y: scroll;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }

  @media (max-width: 480px) {
    width: 100%;
    height: auto;
  }
`;

const Screenshot = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  display: block;
`;

export default AboutApp;
